import { roundForStr } from '@/utils/math'
import { dateToString } from '@/filters/texts'
import { makeSeanceTemplate, SeanceTemplate } from '@/types/youth'

export enum GroupType {
  CHILDREN = 1,
  TEENAGERS = 2,
}

export enum ModelType {
  CHILDREN = 1, // Accueil de loisirs enfant
  TEENAGERS = 2, // Projets ado
}

export class CafAgeGroup {
  constructor(
    public id: number,
    public startAge: number,
    public endAge: number,
    public name: string,
    public groupType: GroupType
  ) {
  }
}

export function makeCafAgeGroup(jsonData: any = null): CafAgeGroup {
  if (!jsonData) {
    jsonData = {}
  }
  return new CafAgeGroup(
    jsonData.id || 0,
    jsonData.start_age || 0,
    jsonData.end_age || 0,
    jsonData.name || '',
    jsonData.group_type
  )
}

export class Place {
  constructor(
    public id: number,
    public name: string
  ) {
  }
}

export function makePlace(jsonData: any = null): Place {
  if (!jsonData) {
    jsonData = {}
  }
  return new Place(
    jsonData.id || 0,
    jsonData.name || ''
  )
}

export class CafReportLine {
  constructor(
    public youthHomeNumber: number,
    public grouperId: number,
    public placeId: number,
    public ageGroupId: number,
    public modelDurationSum: number,
    public seanceDurationSum: number,
    public clockingDurationSum: number,
    public realDurationSum: number,
    public paidDurationSum: number,
    public cafRealDurationSum: number,
    public cafPaidDurationSum: number,
    public cafEligibleDurationSum: number,
    public childrenCount: number
  ) {
  }
}

export function makeCafReportLine(jsonData: any = null): CafReportLine {
  if (jsonData === null) {
    jsonData = {}
  }
  return new CafReportLine(
    jsonData.youth_home || 0,
    jsonData.seance_type || jsonData.category || 0,
    jsonData.place || 0,
    jsonData.age_group || 0,
    jsonData.model_duration_sum || 0,
    jsonData.seance_duration_sum || 0,
    jsonData.clocking_duration_sum || 0,
    jsonData.real_duration_sum || 0,
    jsonData.paid_duration_sum || 0,
    jsonData.caf_real_duration_sum || 0,
    jsonData.caf_paid_duration_sum || 0,
    jsonData.caf_eligible_duration_sum || 0,
    jsonData.children_count || 0
  )
}

export class CafConvention {
  constructor(
    public id: number,
    public number: number,
    public name: string,
    public description: string
  ) {
  }
}

export function makeCafConvention(jsonData: any = null): CafConvention {
  if (!jsonData) {
    jsonData = {}
  }
  return new CafConvention(
    jsonData.id || 0,
    jsonData.number || 0,
    jsonData.name || '',
    jsonData.description || ''
  )
}

export class CafModel {
  constructor(
    public id: number,
    public number: number,
    public convention: CafConvention,
    public place: Place,
    public duration: number,
    public seanceStartsAt: string,
    public seanceEndsAt: string,
    public seanceStartsAt2: string,
    public seanceEndsAt2: string,
    public modelType: ModelType,
    public modelTypeName: string,
    public declaration: string
  ) {
  }

  public getLabel(): string {
    return this.convention.name
  }

  public fullName(): string {
    if (this.id === 0) {
      return 'Aucun modèle CAF'
    } else {
      let text = (this.convention.name || 'Pas de convention')
      text += ' - ' + roundForStr(this.duration) + 'h'
      text += ' - ' + this.place.name
      if (this.modelType === ModelType.CHILDREN) {
        text += ' - Accueil de loisirs enfant'
      } else if (this.modelType === ModelType.TEENAGERS) {
        text += ' - Projet ado'
      }
      text += ' [' + this.number + ']'
      return text
    }
  }
}

export function makeCafModel(jsonData: any = null): CafModel {
  if (!jsonData) {
    jsonData = {}
  }
  return new CafModel(
    jsonData.id || 0,
    jsonData.number || 0,
    makeCafConvention(jsonData.convention),
    makePlace(jsonData.place),
    jsonData.duration,
    jsonData.seance_starts_at,
    jsonData.seance_ends_at,
    jsonData.seance_starts_at2,
    jsonData.seance_ends_at2,
    jsonData.model_type,
    jsonData.model_type_name || '',
    jsonData.declaration || ''
  )
}

export class CafTemplateModel {
  constructor(
    public id: number,
    public model: CafModel,
    public seanceTemplate: SeanceTemplate
  ) {
  }
}

export function makeCafTemplateModel(jsonData: any = null): CafTemplateModel {
  if (!jsonData) {
    jsonData = {}
  }
  return new CafTemplateModel(
    jsonData.id || 0,
    makeCafModel(jsonData.model),
    makeSeanceTemplate(jsonData.seance_template)
  )
}

export class ReportDataGeneration {
  constructor(
    public id: number,
    public startDataDate: Date,
    public endDataDate: Date,
    public generatedBy: string,
    public generatedOn: Date
  ) {
  }

  public dataName(): string {
    const fromDate = dateToString(this.startDataDate, 'DD/MM/YYYY')
    const toDate = dateToString(this.endDataDate, 'DD/MM/YYYY')
    return 'du ' + fromDate + ' au ' + toDate
  }

  public getGeneratedOn(): string {
    return dateToString(this.generatedOn, 'DD/MM/YYYY HH:mm')
  }
}

export function makeReportDataGeneration(jsonData: any = null): ReportDataGeneration {
  if (!jsonData) {
    jsonData = {}
  }
  return new ReportDataGeneration(
    jsonData.id || 0,
    jsonData.start_data_date,
    jsonData.end_data_date,
    jsonData.generated_by || '',
    jsonData.generated_on
  )
}
